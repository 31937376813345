import type { PageProps } from 'gatsby';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { PageLayout } from 'src/layouts/page-layout';
import { sendAnalytics } from 'utils/analytics/google';

export const ConfirmSubscription = ({ location }: PageProps) => {
  const [error, setError] = useState<string>('');
  const { token, page_url, email, action } = queryString.parse(location.search);

  const newsletterUpdate = async () => {
    const response = await fetch(`/api/newsletter/${action}`, {
      method: 'POST',
      body: JSON.stringify({ token, page_url, email }),
    });

    if (response.status === 200) {
      action === 'subscribe'
        ? sendAnalytics('subscribeToUpdates', { email: email as string, pageUrl: page_url as string })
        : sendAnalytics('unsubscribeFromUpdates', { email: email as string, pageUrl: page_url as string });
      setTimeout(() => {
        window.location.href = page_url as string;
      }, 3000);
    } else {
      setError('An error occurred. Please try again later.');
    }
  };

  useEffect(() => {
    document.cookie = `token=${token}`;
    newsletterUpdate();
  }, []);

  return (
    <PageLayout location={location} isLargeContent title="Confirm Subscription">
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold">Redirecting...</h1>
        <p className="w-auto">You will be redirected to the page that you subscribed to in a few seconds...</p>
        {error && <p className="w-auto text-red-500">{error}</p>}
      </div>
    </PageLayout>
  );
};

export default ConfirmSubscription;
